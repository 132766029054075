const tycText = `
ULTIMA ACTUALIZACION: 29/12/2022
CONTRATO DE ADHESIÓN - LEY 24.240- DEFENSA DEL CONSUMIDOR.
Este contrato describe los términos y condiciones (los "Términos y Condiciones") aplicables al uso de los servicios ofrecidos por UITICK S.A.S. CUIT 30-71789538-6, domiciliada en Calle LUIS JOSÉ DE TEJEDA Nº 4014. CP 5009 - Ciudad de Córdoba - Córdoba (“TICKAZO”) para la compra, por cuenta y orden de un tercero, de entradas digitales (los "Tickets") de eventos (los "Eventos") en la aplicación móvil ios “tickazo”, aplicación movil android “tickazo”, el sitio web www.tickazo.com y/o a través de cualquier otro canal de comercialización que TICKAZO utilice en el futuro (la “Plataforma”). Cualquier persona que desee adquirir un Ticket (el "Cliente") podrá hacerlo sujetándose a estos Términos y Condiciones, junto con todas las demás políticas y principios que rigen el uso de TICKAZO.
 
CUALQUIER PERSONA QUE NO ACEPTE ESTOS TÉRMINOS Y CONDICIONES DE VENTA, LOS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LOS SERVICIOS PRESTADOS POR TICKAZO.
1. LOS SERVICIOS
TICKAZO no es organizador ni proveedor de los servicios referidos en los Tickets y solo actúa en nombre y por cuenta y orden del organizador o promotor o productor del Evento (el “Organizador”). El Organizador es el único y directo responsable del Evento y quien determina las condiciones de venta de los Tickets. Cuando el Cliente adquiera un Ticket está celebrando un contrato directamente con el Organizador que ofrece su servicio a través de la Plataforma. Antes de confirmar su solicitud de compra, revise estos términos y condiciones. El solo uso de la Plataforma implica la aceptación de todas y cada uno de los términos y condiciones aquí establecidos.
 
El Cliente no podrá utilizar los servicios ni aceptar estos Términos y Condiciones si no está legalmente capacitado para celebrar un contrato vinculante con TICKAZO, o si tiene prohibida o legalmente excluida la recepción o utilización de los servicios, en virtud de la legislación del país de residencia o desde el que accede o utiliza los servicios.
 
Estos Términos y Condiciones podrán ser modificados por TICKAZO de tanto en tanto, lo que será informado cambiando la fecha de la actualización que se encuentra en la parte superior de este documento/aviso. Si los cambios en los Términos y Condiciones son sustanciales/materiales, TICKAZO notificará al Cliente por correo electrónico o por mensaje de texto (enviado a la dirección de correo electrónico o número de celular especificada en su cuenta), mediante un aviso en los servicios antes de que el cambio entre en vigencia, o según lo requiera la ley.
2. REGISTRACION
Todo Cliente que desee utilizar el servicio debe registrarse en la aplicación móvil ios “tickazo” o en la aplicación movil android “tickazo” y aceptar estos Términos y Condiciones. Para registrarse es obligatorio completar el formulario de registro de orden en todos sus campos con datos válidos e información personal exacta, precisa y verdadera (los "Datos Personales"). El Cliente se encuentra obligado a actualizar los Datos Personales conforme resulte necesario. TICKAZO NO se responsabiliza por la certeza y validez de los Datos Personales provistos por el Cliente ni por la falta de actualización de los mismos. El Cliente garantiza y responde, en cualquier caso, por la veracidad, exactitud, integridad, vigencia y autenticidad de los Datos Personales ingresados. Toda la información y los Datos Personales ingresados por el Cliente tienen carácter de declaración jurada.
 
TICKAZO se reserva el derecho de solicitar comprobantes, documentación y/o información adicional a efectos de corroborar la información proporcionada por un Cliente en materia de Datos Personales, así como de suspender temporal o definitivamente a aquel Cliente cuyos Datos Personales no hayan podido ser confirmados.
 
TICKAZO se reserva el derecho de rechazar una solicitud de registro o de cancelar o suspender, temporal o definitivamente una registración ya existente, en caso de detectar incongruencias, inconsistencias o datos falsos en la información recibida, o en caso de detectar actividades sospechosas, sin que tal decisión genere al Cliente (o potencial Cliente) derechos de indemnización o resarcimiento.
3. DATOS PERSONALES
Al utilizar los servicios de TICKAZO, el titular de los Datos Personales presta su consentimiento conforme a lo establecido en la Ley 25.326 de Protección de Datos Personales, y declara conocer y aceptar que sus Datos Personales integren la base de datos de TICKAZO, otorgando por la presente, autorización expresa para: (i) el tratamiento automatizado de dichos datos e información y (ii) su utilización para servicios actuales o futuros, que desarrolle TICKAZO.
 
El Cliente conoce y consiente que, al aceptar el casillero titulado “Acepto los términos y condiciones del servicio”, sus Datos Personales serán cedidos por TICKAZO al Organizador del Evento quien podrá utilizarlo para fines publicitarios. El Cliente podrá revocar el consentimiento otorgado en cualquier momento comunicándose con TICKAZO a través del siguiente enlace: https://forms.gle/S3MEnk8Avo9Ghxrj8
 
El titular de los Datos Personales tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita y a intervalos no inferiores a seis meses, salvo que se acredite tener un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley 25.326. El titular de los datos, tiene además la posibilidad de ejercer los derechos de rectificación y supresión de los datos conforme artículo 6, inciso “e” de la Ley 25.326.
 
Ley 25.326- Artículo 27- inciso 3. — (Archivos, registros o bancos de datos con fines de publicidad). “El titular podrá en cualquier momento solicitar el retiro o bloqueo de su nombre de los bancos de datos a los que se refiere el presente artículo”.
 
Decreto 1558/01 –Anexo I- Artículo.- 3º párrafo. “En toda comunicación con fines de publicidad que se realice por correo, teléfono, correo electrónico, Internet u otro medio a distancia a conocer, se deberá indicar, en forma expresa y destacada, la posibilidad del titular del dato de solicitar el retiro o bloqueo, total o parcial, de su nombre de la base de datos. A pedido del interesado, se deberá informar el nombre del responsable o Consumidor del banco de datos que proveyó la información”.
 
La DIRECCIÓN NACIONAL DE PROTECCIÓN DE DATOS PERSONALES, es el Órgano de Control de la Ley 25.326, y tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al incumplimiento de las normas sobre protección de datos personales.
4. EL EVENTO
Si la fecha de un Evento variara por alguna circunstancia, los Tickets serán válidos para la fecha definitiva que fije e informe el Organizador. La reprogramación del Evento en ningún caso dará derecho al Cliente a reclamar la devolución del valor del Ticket.
 
Si un Evento no fuera reprogramado y en cambio es suspendido o cancelado el Cliente tendrá derecho a solicitar la devolución del valor del Ticket. Todas las devoluciones serán responsabilidad del Organizador, pudiendo TICKAZO colaborar en la gestión de dichas devoluciones al Cliente.
 
El Cliente tiene derecho a revocar la aceptación durante el plazo de 10 (diez) días corridos contados a partir de la fecha en que se celebre el contrato, sin responsabilidad alguna, salvo que ya hubiese utilizado el Ticket. Esta facultad no puede ser dispensada ni renunciada y a tal fin se coloca en la página web, en la aplicación móvil ios y en la aplicación móvil android un botón de arrepentimiento bajo la denominación “Boton de Arrepentimiento” conforme lo dispuesto en la Resolución 424/2020 Secretaría de Comercio Interior. Teniendo en cuenta que TICKAZO sólo actúa en nombre y por cuenta y orden del Organizador, cualquier reclamo será realizado exclusivamente ante el Organizador. Sin perjuicio de ello, TICKAZO , sin asegurar resultado o garantía alguna, podrá ofrecer a su exclusivo criterio sus servicios en base a esfuerzos razonables para gestionar dichos reclamos.
 
En los casos de suspensión, cancelación, reprogramación del Evento o de revocación de la compra por parte del Cliente, antes referidos, los cargos por los servicios prestados por TICKAZO no serán devueltos.
 
Los cargos por servicios prestados por TICKAZO no serán devueltos en caso de suspensión, cancelación, reprogramación del Evento o de revocación de la compra por parte del Cliente.
 
El Organizador se reserva el derecho de agregar, modificar o sustituir artistas o participantes del Evento, variando los programas, precios y ubicaciones difundidas, así como la capacidad del auditorio, conforme esto sea informado oportunamente. El Organizador se reserva el derecho de admisión y permanencia. Las llegadas tarde del público implicarán que el ingreso del mismo se efectúe en el intervalo o cuando el Organizador lo considere oportuno a su exclusivo criterio.
 
Antes de ingresar al Evento, el Cliente, como espectador, podrá ser sometido a un chequeo por parte del personal de seguridad, impidiéndose la entrada a quien se niegue a recibirlo, no acarreando esto ninguna responsabilidad o consecuencia para el Organizador.
 
No será permitido el ingreso a un Evento con pirotecnia, grabadoras, filmadoras, cámaras de fotografía y de video, armas de fuego, cuchillos o cualquier otro objeto filo punzante, ni cualquier elemento similar a los mencionados a criterio del Organizador, pudiendo los mismos ser retirados del lugar y destruido su contenido. El Organizador podrá solicitar que se apague todo equipo de radio llamada o teléfono celular antes del acceso al Evento.
5. ADQUISICIÓN DEL TICKET
La Plataforma le permite al Cliente adquirir un Tickets a través de Internet u otros medios digitales similares. El sistema selecciona el mejor lugar disponible al momento de realizar su compra, de acuerdo al nivel de precio solicitado.
 
Cada Ticket comprado a través de la Plataforma, se encuentra sujeta a un cargo por servicio adicional del precio del Ticket (los cuales se informan en la lista de precios de cada Evento).
 
Los cargos por servicio que hubieren sido efectivamente prestados por TICKAZO no serán en ningún caso devueltos al Cliente, ni aún en caso de suspensión, cancelación o reprogramación del Evento.
 
El adquirente de Tickets es responsable de chequear, aceptar y confirmar los términos y condiciones de su compra previo a realizarla, incluyendo sin limitación: (i) la descripción y especificación del Evento para el cual adquirió los Tickets, (ii) (ii) los precios y condiciones de pago, (iii) los costos y cargos adicionales. Por ello antes de completar su compra, por favor revise cuidadosamente el nombre del Evento, la sección y la ubicación deseada, ya que una vez confirmada la compra no se permiten cambios, reembolsos y/o devoluciones por este motivo.
 
La confirmación de la compra que el Cliente realiza con su tarjeta de crédito o débito está sujeta a la autorización de la empresa emisora de ésta.
 
Para evitar fraudes en la adquisición de Tickets, TICKAZO se reserva el derecho de rechazar una compra si el pago de la misma se realiza con una tarjeta de crédito o débito que está vinculada a excesivas cancelaciones previas.
 
TICKAZO NUNCA SOLICITARÁ AL CLIENTE INFORMACIÓN DETALLADA DE SUS MEDIOS DE PAGO EN CASO QUE EL CLIENTE NECESITE EFECTUAR ALGUNA GESTIÓN.
6. ENTREGA DE LOS TICKETS
Los Tickets serán entregados mediante la aplicación móvil ios o aplicación móvil android.
 
Para tener acceso a la Plataforma el Cliente debe tener la aplicación de ios o la aplicación de android instalada en su celular.
 
Los Tickets sólo serán considerados válidos para ingresar al Evento siempre que sean adquiridos a través de la Plataforma. Para evitar situaciones tales como reventa ilegal, falsificaciones, uso de Tickets robados, Tickets con doble acceso entre otras, le recomendamos comprar sus Tickets únicamente a través del sistema en forma directa.
 
Los Tickets no podrán ser utilizados en ningún caso para su reventa y/o aplicación comercial o de promoción alguna sin la previa autorización por escrito del Organizador o de TICKAZO.
7. OTRAS DISPOSICIONES
Cancelación o suspensión. En caso de violación de estos Términos y Condiciones por parte del Cliente, TICKAZO se reserva el derecho a su sola discreción y sin notificación alguna de suspender o cancelar la registración del Cliente al sitio.
 
Consultas del Cliente. Ante cualquier consulta sobre estos Términos y Condiciones o con cualquier otra cuestión vinculada a los servicios prestados por TICKAZO al igual que cualquier cuestión relativa a sus datos personales, no dude en comunicarse con TICKAZO a ventas@tickazo.com.
 
Resolución de Conflictos. Cualquier controversia o conflicto entre el Cliente y TICKAZO en relación con la utilización del servicio de TICKAZO o de estos Términos y Condiciones se regirá por la ley de la República Argentina y será resuelto por los Tribunales Ordinarios con asiento en Córdoba.
`;

export default tycText;