
import EventCard from './EventCard';
import Grid2 from '@mui/material/Unstable_Grid2';

// Fetch events from the API
const EventsList = ({ events }) => {
    return (
        <div
            style={{
                margin: '16px',
                justifyContent: 'center',
                display: 'flex',
            }}
            >
            {events.length === 0 ? (
                <div
                    style={{ display: 'flex', justifyContent: 'center' }}
                    >
                    <p> No hay eventos para mostrar </p>
                </div>
            ) : (
                <Grid2 container spacing={2} xs={12} >
                    {events.map((event) => (
                        <Grid2 xs={12} sm={6} md={3} key={event.id}>
                            <EventCard key={event.id} event={event} />
                        </Grid2>
                    ))}
                </Grid2>
            )}
        </div>
    );
};

export default EventsList;