import { Android, Apple } from '@mui/icons-material';
import { useTheme } from '@emotion/react';

function StoreButton({type}) {
    const theme = useTheme();
    const storeInfo = [
        {
          type: 'android',
          icon: <Android style={{ marginRight: '10px' }} />,
          name: 'Google Play',
          url: 'https://play.google.com/store/apps/details?id=com.tickazo.tickazo&pli=1',
        },
        {
          type: 'apple',
          icon: <Apple style={{ marginRight: '10px' }} />,
          name: 'App Store',
          url: 'https://apps.apple.com/ar/app/tickazo/id6446330026',
        },
    ];

    const { icon, name, url } = storeInfo.find((info) => info.type === type);

    return (
        <div
        style={{
            height: '50px',
            width: '250px',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '25px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            margin: '8px 0',
        }}
        onClick={() => window.open(url)}
        >
        {icon}
        <span style={{ fontWeight: 'bold' }}>{name}</span>
        </div>
    );
}

export default StoreButton;