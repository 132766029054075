import React from "react";

  const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer>
        <div className="container">
          <span className="text-muted">tickazo - Copyright©️ {currentYear}.
            Todos los derechos reservados.</span>
        </div>
      </footer>
    );
  }

export default Footer;