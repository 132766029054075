import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import esLocale from 'date-fns/locale/es';
import { format, isValid } from 'date-fns';

import capitalize from '@mui/material/utils/capitalize';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';
import LocationOn from '@mui/icons-material/LocationOn';

const EventCard = ({ event }) => {
    let startDate = '';
    let startTime = '';
    let endTime = '';
    if (isValid(new Date(event.fecha_start)) && isValid(new Date(event.fecha_end))) {
        const [year, month, day] = event.fecha_start.split('-');
        startDate = format(new Date(year, month - 1, day), 'EEE, dd MMM', { locale: esLocale });
    }
    // Get the first 5 characters of the time
    startTime = event.hora_start.substring(0, 5);
    endTime = event.hora_end.substring(0, 5);
    return (
        <Card sx={{ maxWidth: 300, margin: 'auto' }}>
            <CardActionArea
                href={`/events?eventId=${event.id}`}
            >
                <CardMedia
                    component="img"
                    height="168.75"
                    image={event.img_header}
                    alt={event.nombre}
                    style={{ objectFit: 'fill' }}
                />
                <CardContent>
                    <Typography>
                        <span style={{ fontWeight: 'bold', fontSize:'1.5em' }}>{event.nombre}</span>
                    </Typography>
                    <Typography variant="body2" color='primary'>
                        {capitalize(startDate)} - {startTime} - {endTime}
                    </Typography>
                    <Box
                        display='flex'>
                        <LocationOn sx={{ fontSize: '20px' }} ></LocationOn>
                        <Typography variant="body2" color='text.secondary'>
                            {capitalize(event.predio.nombre)}, {event.predio.localidad}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default EventCard;