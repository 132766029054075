import React from 'react';
import tycText from '../components/TycUsersText';

function TycUserPage() {
    const paragraphs = tycText.split('\n');
    return (
        <div style={{ padding: 16 }}>
            <h1>Terminos y condiciones</h1>
            {paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            ))}
        </div>
    )
}

export default TycUserPage;