import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet, } from 'react-helmet';
import CircularProgress from '@mui/material/CircularProgress';
import EventsList from '../components/EventsList';


function CompanyEventsPage() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        async function fetchEvents() {
            try {
                const response = await fetch(`https://api.tickazo.com/eventos?empresa__id=${id}`);
                const data = await response.json();
                setEvents(data.results);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
        fetchEvents();
    }, [id]);
    
    return (
        <>
            {
                useEffect(() => {
                    <Helmet>
                        <title>Tickazo | Tu DNI es tu entrada!</title>
                        <meta name="description" content="¡Tu DNI es tu entrada a los mejores eventos! Descubre, reserva y vive momentos inolvidables en conciertos, festivales, deportes y teatro. Compra tus entradas hoy mismo."/>
                        <meta property="og:title" content="Tickazo | Tu DNI es tu entrada!" />
                        <meta property="og:url" content="https://tickazo.com/images/tickazo-logo.png" />
                        <meta property="og:type" content="website" />
                        <meta property="og:description" content="¡Tu DNI es tu entrada a los mejores eventos! Descubre, reserva y vive momentos inolvidables en conciertos, festivales, deportes y teatro. Compra tus entradas hoy mismo."/>
                        <meta property="og:image" content="https://tickazo.com/images/tickazo-logo.png" />
                        <meta name="twitter:card" content="summary_large_image" />
                        <meta name="twitter:title" content="Tickazo | Tu DNI es tu entrada!" />
                        <meta name="twitter:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar." />
                        <meta name="twitter:image" content="https://tickazo.com/images/tickazo-logo.png" />
                        <meta name="twitter:image:alt" content="Tickazo logo" />
                    </Helmet>
                }, [])
            }
            {
                loading ? (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100vh'
                    }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '16px',
                            }}
                            >
                            <img
                                src={events[0]?.empresa?.logo}
                                maxwidth={300}
                                height={225}
                                style={{ borderRadius: '5%', objectFit: 'contain' }}
                                alt={events[0]?.empresa?.nobmre}
        
                            />
                        </div>
                        <EventsList events={events} />
                    </>
                )
            }
        </>
    );
}

export default CompanyEventsPage;