import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: '5px',
    backgroundColor: 'rgb(80, 80, 80)',
    height: '50px',
    width: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  title: {
    color: 'white',
  },
  subtitle: {
    color: 'white',
  },
}));

function CustomTiles(props) {
  const classes = useStyles();

  return (
    <ListItem disableRipple disableTouchRipple button style={{ padding: 0 }}>
      <ListItemIcon className={classes.icon} style={{ marginRight: 16 }}>
        <props.icon color='white' />
      </ListItemIcon>
      <ListItemText
        primary={props.title}
        secondary={props.subtitle}
        classes={{ primary: classes.title, secondary: classes.subtitle }}
      />
    </ListItem>
  );
}

export default CustomTiles;