import * as React from "react";
import { useState } from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
            <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                    flexGrow: 1,
                    fontFamily: 'Poppins Bold, sans-serif',
                    fontSize: '2rem',
                    textDecoration: 'none',
                    color: 'primary.main',
                }}
            >
            tickazo
          </Typography>
          {isMobile ? (
            <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="menu"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: "inherit" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="nav-menu"
                anchorEl={anchorElNav}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={handleCloseNavMenu}>
                    <Typography variant="h6" noWrap component='a' href="https://admin.tickazo.com" sx={{ mr: 3, color: "inherit", textDecoration: "none" }}>
                        Crea un evento!
                    </Typography>
                </MenuItem>
                {/* <MenuItem onClick={handleCloseNavMenu}>About</MenuItem> */}
                <MenuItem onClick={handleCloseNavMenu}>
                    <Typography variant="h6" noWrap component='a' href="https://forms.gle/Vx5cfSPH61Ks1FNS8" sx={{ color: "inherit", textDecoration: "none" }}>
                        Contacto
                    </Typography>
                </MenuItem>
              </Menu>
            </Box>
          ) : (
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" noWrap component='a' href="https://admin.tickazo.com" sx={{ mr: 3, color: "inherit", textDecoration: "none" }}>
                Crea un evento!
              </Typography>
              {/* <Typography variant="h6" noWrap sx={{ mr: 3 }}>
                Nosotros
              </Typography> */}
              <Typography variant="h6" noWrap component='a' href="https://forms.gle/Vx5cfSPH61Ks1FNS8" sx={{ color: "inherit", textDecoration: "none" }}>
                Contacto
              </Typography>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;