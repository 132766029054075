import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet";
import CircularProgress from '@mui/material/CircularProgress';
import EventsList from "../components/EventsList";

function HomePage() {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        async function fetchEvents() {
            try {
                const response = await fetch('https://api.tickazo.com/eventos');
                const data = await response.json();
                console.log(data);
                setEvents(data.results);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                console.log(error);
            }
        }
        fetchEvents();
    }, []);
    
    return (
        <>
            <Helmet>
                <title>Tickazo | Tu DNI es tu entrada!</title>
                <meta name="description" content="¡Tu DNI es tu entrada a los mejores eventos! Descubre, reserva y vive momentos inolvidables en conciertos, festivales, deportes y teatro. Compra tus entradas hoy mismo."/>
                <meta property="og:title" content="Tickazo | Eventos" />
                <meta property="og:url" content="https://tickazo.com" />
                <meta property="og:type" content="website" />
                <meta property="og:description" content="¡Tu DNI es tu entrada a los mejores eventos! Descubre, reserva y vive momentos inolvidables en conciertos, festivales, deportes y teatro. Compra tus entradas hoy mismo."/>
            </Helmet>
            {loading ? (
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <CircularProgress />
                </div>
            ) : (
                <EventsList events={events}/>
            )}
        </>
    );
}

export default HomePage;