import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import EventInfo from "../components/EventInfo";
import { Helmet } from "react-helmet";
import EventImage from "../components/EventImg";
import { TextField, Button, Select, MenuItem, FormControl, InputLabel, InputAdornment, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


function EventPage() {

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const eventId = searchParams.get("eventId");

  const [event, setEvent] = useState({});

  useEffect(() => {
    async function fetchEvent() {
      try {
        const response = await fetch(`https://api.tickazo.com/eventos/${eventId}`);
        const data = await response.json();
        setEvent(data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchEvent();
  }, [eventId]);

  if (loading) {
    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh'
    }}>
        <CircularProgress color="primary"/>
    </div>;
  }
  
  
  return (
    <>
      <Helmet>
        <title>Tickazo | {event.nombre}</title>
        <meta name="description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:title" content={`Tickazo | ${event.nombre}`} />
        <meta property="og:url" content={`https://tickazo.com/events?eventId=${eventId}`} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar."/>
        <meta property="og:image" content={event.img_poster} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Tickazo | ${event.nombre}`} />
        <meta name="twitter:description" content="Tu DNI es tu entrada para los mejores eventos!. Disfruta de un evento en vivo en Tickazo. Compra tus entradas hoy mismo y vive la experiencia de un concierto, festival, deporte o teatro en vivo desde la comodidad de tu hogar." />
        <meta name="twitter:image" content="https://tickazo.com/images/tickazo-logo.png" />
        <meta name="twitter:image:alt" content="Tickazo logo" />
      </Helmet>
      <div style={{ margin: '16px', display: 'flex', justifyContent: 'center' }}>
        <Grid container alignItems="center" className="event-page-container">
          <Grid item xs={12} sm={6} style={{ display: 'flex', justifyContent: 'center' }}>
            <EventImage img_header={event.img_header} nombre={event.nombre} />
          </Grid>
          <Grid item xs={12} sm={3}>
            <EventInfo event={event} />
          </Grid>
        </Grid>
      </div>
      {/* <BuyForm eventId={eventId} /> */}
    </>
  );
}

// const useStyles = makeStyles((theme) => ({
//   whiteUnderline: {
//     '&:before': {
//       borderBottomColor: 'white',
//     },
//     '&:hover:not(.Mui-disabled):before': {
//       borderBottomColor: 'white',
//     },
//     '&:after': {
//       borderBottomColor: 'white',
//     },
//   },
// }));

// function BuyForm({ eventId }) {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     nombre: '',
//     apellido: '',
//     celular: '',
//     dateOfBirth: '',
//     dni: '',
//     sexo: '',
//     cantidad: '',
//     ticket_id: 0, 
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Handle form submission logic here
//     console.log(formData);

//     // post request to endpoint
//     fetch(`https://api.tickazo.com/ecommerce/parche`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         ...formData,
//         evento: eventId,
//       }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         console.log('Success:', data);
//         alert('Compra realizada con éxito');
//         window.location.href = data['payment_link'];
//       })
//       .catch((error) => {
//         console.error('Error:', error);
//         alert('Error al realizar la compra');
//       });
//   };

//   const total = formData.cantidad * 6000;

//   return (
//     <>
//       <div style={{ margin: '16px', display: 'flex', justifyContent: 'center' }}>
//         <form onSubmit={handleSubmit}>
//           <Typography variant="h4" style={{ color: 'white', marginBottom: '16px', textAlign: 'center' }}>
//             Realizar compra
//           </Typography>
//           <Typography variant="subtitle1" style={{ color: 'white', marginBottom: '16px' }}>
//             Las entradas quedan guardadas en el sistema con tu DNI. Para entrar al evento, solamente tenes que presentarte en la puerta con el DNI y seguridad las procesará.
//           </Typography>
//           <TextField
//             label="Nombre"
//             name="nombre"
//             type="text"
//             required
//             value={formData.nombre}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             InputProps={{
//               style: { color: 'white' },
//               classes: {
//                 underline: classes.whiteUnderline,
//               },
//             }}
//             InputLabelProps={{
//               style: { color: 'white' },
//             }}
//           />
//           <TextField
//             label="Apellido"
//             name="apellido"
//             type="text"
//             required
//             value={formData.apellido}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             InputProps={{
//               style: { color: 'white' },
//               classes: {
//                 underline: classes.whiteUnderline,
//               },
//             }}
//             InputLabelProps={{
//               style: { color: 'white' },
//             }}
//           />
//           <TextField
//             label="Celular"
//             name="celular"
//             type='number'
//             required
//             value={formData.celular}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             helperText="Codigo de area sin el 0 y sin el 15 (Ej: 3513985814)"
//             InputProps={{
//               startAdornment: <InputAdornment position="start" style={{ color: 'white' }}>+54</InputAdornment>,
//               style: { color: 'white' },
//               classes: {
//                 underline: classes.whiteUnderline,
//               },
//             }}
//             InputLabelProps={{
//               style: { color: 'white' },
//             }}
//             FormHelperTextProps={{
//               style: { color: 'white' },
//             }}
//           />
//           <TextField
//             label="DNI"
//             name="dni"
//             type="number"
//             required
//             value={formData.dni}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             InputProps={{
//               style: { color: 'white' },
//               classes: {
//                 underline: classes.whiteUnderline,
//               },
//             }}
//             InputLabelProps={{
//               style: { color: 'white' },
//             }}
//           />
//           <TextField
//             label="Fecha de nacimiento"
//             name="dateOfBirth"
//             type="date"
//             required
//             value={formData.dateOfBirth}
//             onChange={handleChange}
//             fullWidth
//             margin="normal"
//             // placeholder="dd/mm/yyyy"
//             InputProps={{
//               style: { color: 'white' },
//               classes: {
//                 underline: classes.whiteUnderline,
//               },
//             }}
//             InputLabelProps={{
//               style: { color: 'white' },
//             }}
//           />
//           <FormControl fullWidth margin="normal" required>
//             <InputLabel style={{ color: 'white' }}>Sexo</InputLabel>
//             <Select
//               name="sexo"
//               value={formData.sexo}
//               onChange={handleChange}
//               style={{ color: 'white' }}
//               classes={{
//                 underline: classes.whiteUnderline,
//               }}
//             >
//               <MenuItem value="M">Masculino</MenuItem>
//               <MenuItem value="F">Femenino</MenuItem>
//               <MenuItem value="X">Otro</MenuItem>
//             </Select>
//           </FormControl>
//           <div style={{ margin: '16px 0', color: 'white' }}>
//             <p>Entrada general $6000</p>
//             <TextField
//               label="Cantidad"
//               name="cantidad"
//               type="number"
//               required
//               value={formData.cantidad}
//               onChange={handleChange}
//               fullWidth
//               margin="normal"
//               InputProps={{
//                 style: { color: 'white' },
//                 classes: {
//                   underline: classes.whiteUnderline,
//                 },
//               }}
//               InputLabelProps={{
//                 style: { color: 'white' },
//               }}
//             />
//           </div>
//           <div style={{ margin: '16px 0', color: 'white' }}>
//             <Typography variant="h6">Total: ${total}</Typography>
//           </div>
//           <Button type="submit" variant="contained" color="primary">
//             Confirmar compra
//           </Button>
//         </form>
//       </div>
//     </>
//   );
// }

export default EventPage;