function EventImage(props) {
    return (
      <img
        src={props.img_header}
        style={{ 
          maxWidth: '100%', 
          height: 'auto', 
          borderRadius: '5%' 
        }}
        className="event-image-web"
        alt={props.nombre}
      />
    );
  }

export default EventImage;